// @ts-nocheck
import {
  Button,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import useGetRetailers from "hooks/useGetRetailers";
import { deleteRetailer, getUploadImageUrl } from "http/retailer";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import CreateRetailer from "./components/CreateRetailer";
import ViewDetailedRetailer from "./components/ViewDetailedRetailer";
import ViewRetailer from "./components/ViewRetailer";
import ChangeImageRetailer from "./components/ChangeImageRetailer";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function Retailers() {
  const [searchParams] = useSearchParams();

  const [isDeleting, setIsDeleting] = useState(false);
  const zoneFilter = searchParams.has("zone")
    ? searchParams.get("zone")
    : false;
  const [SelectedRetailer, setSelectedRetailer] = useState(null);
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const {
    isOpen: isViewDetailedOpen,
    onOpen: onViewDetailedOpen,
    onClose: onViewDetailedClose,
  } = useDisclosure();

  const { isLoading, data: retailers, error, forceRefetch } = useGetRetailers();

  const onViewDetails = (id) => {
    setSelectedRetailer(retailers.find((z) => z.sequenceId === id));
    onViewDetailedOpen();
  };

  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();

  const onEditDetails = (id) => {
    const temp = retailers.find((z) => z.sequenceId === id);
    if (temp) {
      setSelectedRetailer({ ...SelectedRetailer, ...temp });
      onAddOpen();
    }
  };

  const onDeleteRetailer = async (id) => {
    const temp = retailers.find((z) => z.sequenceId === id);
    if (temp) {
      setIsDeleting(true);
      const response = await deleteRetailer(temp.mobileNo);
      if (response.error) errorToast("Delete Retailer", response.error);
      else {
        successToast("Delete Retailer", response.result);
        forceRefetch({});
      }
      setIsDeleting(false);
    }
  };
  const {
    isOpen: isRetailerImageChangeOpen,
    onOpen: onRetailerImageChangeOpen,
    onClose: onRetailerImageChangeClose,
  } = useDisclosure();

  const onChangeImage = async (mobileNo) => {
    setSelectedRetailer(retailers.find((z) => z.mobileNo === mobileNo));
    onRetailerImageChangeOpen();
  };

  return (
    <>
      <Flex direction={"row-reverse"}>
        <Button
          colorScheme={"brand"}
          leftIcon={<AiOutlinePlus />}
          onClick={() => {
            setSelectedRetailer(null);
            onAddOpen();
          }}
        >
          Add New Retailer
        </Button>
      </Flex>

      <Stack spacing={2} mt={6}>
       
        {isLoading ? (
          <Center
            w={"full"}
            boxShadow={"None"}
            rounded="md"
            p={5}
          >
            <PropagateLoader
              color={'purple'}
              loading={isLoading}
              data-testid="loader"
              speedMultiplier={1} />
          </Center>
        ) : (
          <></>
        )}
        {!isLoading &&
          !error &&
          (retailers === null || retailers.length === 0 || Object.keys(retailers).length === 0) ? (
          <Center
            w={"full"}
          >
            No Active Retailers
          </Center>
        ) : (
          <SimpleGrid
            minChildWidth="300px"
            spacing="40px"
            pl={[0, 0, "80px", "18px", "20px", "40px"]}
          >
             <Heading size={"md"}>Available Retailers</Heading>
            {retailers?.filter((r) => !zoneFilter || r.zone === zoneFilter)
              .map((retailer) => (
                <ViewRetailer
                  isDeleting={isDeleting}
                  data={retailer}
                  onEdit={(id) => onEditDetails(id)}
                  key={retailer.sequenceId}
                  rest={{ my: 5 }}
                  onDetailedView={(id) => onViewDetails(id)}
                  onDelete={onDeleteRetailer}
                  onImageChange={onChangeImage}
                />
              ))}
          </SimpleGrid>
        )}

      </Stack>

      <ViewDetailedRetailer
        data={SelectedRetailer}
        onClose={onViewDetailedClose}
        isOpen={isViewDetailedOpen}
        forceRefetch={forceRefetch}
      />
      <CreateRetailer
        data={SelectedRetailer}
        onClose={onAddClose}
        isOpen={isAddOpen}
        forceRefetch={forceRefetch}
      />
      <ChangeImageRetailer
        title={"Change Profile"}
        url={getUploadImageUrl(
          SelectedRetailer ? SelectedRetailer.mobileNo : ""
        )}
        isOpen={isRetailerImageChangeOpen}
        onClose={() => {
          onRetailerImageChangeClose();
          forceRefetch({});
        }}
        onSuccess={() => { }}
      ></ChangeImageRetailer>
    </>
  );
}
