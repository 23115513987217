import { Stack, Center, SimpleGrid, HStack, Select, Text, Box } from "@chakra-ui/react";
import useFetch from "hooks/useFetch";
import useGetOrders from "hooks/useGetOrders";
import { getOrdersByStatusEndPoint } from "http/order";
import { useEffect, useState } from "react";
import OrderCard from "./Components/OrderCard";
import PropagateLoader from "react-spinners/PropagateLoader";
import useGetOrdersSummary from "hooks/useGetOrderSummary";

export default function Orders() {
  const { isLoading, data, error } = useGetOrders();
  const [orders, setOrders] = useState(null);
  const [url, setUrl] = useState(null);
  const { isLoading: filteredIsLoading, data: filteredOrders, error: filteredError } = useFetch(url);

  const { isLoadingOrderSummary, data: orderSummary } = useGetOrdersSummary();
  console.log(orderSummary)

  useEffect(() => {
    if (data) {
      setOrders(data);
    }
    if (filteredOrders) {
      setOrders(filteredOrders);
    }
  }, [data, filteredOrders]);

  const onStatusChange = (e) => {
    const status = e.target.value;
    setUrl(getOrdersByStatusEndPoint(status));
  }

  return (
    <>
      {isLoadingOrderSummary ? (
        <Center
          w={"full"}
          boxShadow={"None"}
          rounded="md"
          p={5}
        >
          <PropagateLoader
            color={'purple'}
            loading={isLoading}
            data-testid="loader"
            speedMultiplier={1} />
        </Center>
      ) : (
        <HStack justifyContent={"space-between"}>
          
          <Box as='span' rounded="3xl" width={180} height={10} fontWeight='bold' fontSize='medium' color='black' backgroundColor={'yellow'} textAlign='center'> &nbsp;Todays&nbsp;{orderSummary != null ? orderSummary.todays : 0}</Box>
          <Box as='span' rounded="3xl" width={180} height={10} fontWeight='bold' fontSize='medium' color='black' backgroundColor={'green.200'} textAlign='center'>&nbsp;CREATED&nbsp; {orderSummary != null ? orderSummary.created : 0}</Box>
          <Box as='span' rounded="3xl" width={180} height={10} fontWeight='bold' fontSize='medium' color='black' backgroundColor={'green.500'} textAlign='center'>&nbsp;APPROVED&nbsp; {orderSummary != null ? orderSummary.approved : 0}</Box>
          <Box as='span' rounded="3xl" width={180} height={10} fontWeight='bold' fontSize='medium' color='black' backgroundColor={'green.400'} textAlign='center'>&nbsp;PACKING&nbsp; {orderSummary != null ? orderSummary.packing : 0}</Box>
          <Box as='span' rounded="3xl" width={180} height={10} fontWeight='bold' fontSize='medium' color='black' backgroundColor={'yellow'} textAlign='center'>&nbsp;ON_WAY &nbsp; {orderSummary != null ? orderSummary.onWay : 0}</Box>
          <Box as='span' rounded="3xl" width={180} height={10} fontWeight='bold' fontSize='medium' color='black' backgroundColor={'green.700'} textAlign='center'>&nbsp;DELIVERED &nbsp; {orderSummary != null ? orderSummary.delivered : 0}</Box>
          <Box as='span' rounded="3xl" width={180} height={10} fontWeight='bold' fontSize='medium' color='black' backgroundColor={'red'} textAlign='center'>&nbsp;FAILED&nbsp;{orderSummary != null ? orderSummary.failed : 0}</Box>
          <Box as='span' rounded="3xl" width={180} height={10} fontWeight='bold' fontSize='medium' color='black' backgroundColor={'red.600'} textAlign='center'>&nbsp;REJECTED &nbsp; {orderSummary != null ? orderSummary.rejected : 0}</Box>
          <Box as='span' rounded="3xl" width={180} height={10} fontWeight='bold' fontSize='medium' color='black' backgroundColor={'grey'} textAlign='center'> &nbsp;All&nbsp; {orderSummary != null ? orderSummary.total : 0}</Box>
          
          <HStack p={5}>
            <Text w={300}>Filter Orders By Status</Text>
            <Select bg={'brand'} placeholder="Filter Orders By Status" onChange={onStatusChange}>
              <option value="ALL">ALL</option>
              <option value="TODAYS">TODAYS</option>
              <option value="CREATED">CREATED</option>
              <option value="APPROVED">APPROVED</option>
              <option value="PACKING">PACKING</option>
              <option value="OUT_FOR_DELIVERY">ON_WAY</option>
              <option value="DELIVERED">DELIVERED</option>
              <option value="FAILED">FAILED</option>
              <option value="REJECTED">REJECTED</option>
            </Select>
          </HStack>
        </HStack>
      )}
      <Stack spacing={8} mt={6}>

        {isLoading || filteredIsLoading ? (
          <Center
            w={"full"}
            boxShadow={"None"}
            rounded="md"
            p={5}
          >
            <PropagateLoader
              color={'purple'}
              loading={isLoading}
              data-testid="loader"
              speedMultiplier={1} />
          </Center>
        ) : (
          <></>
        )}
        {((!isLoading && !error) || (!filteredIsLoading && !filteredError)) && (orders === null || orders.length === 0) ? (
          <></>
        ) : (
          <></>
        )}
        <SimpleGrid
          minChildWidth="300px"
          spacing="40px"
          pl={[0, 0, "80px", "18px", "20px", "40px"]}
        >
          {orders?.map((cat) => (
            <OrderCard key={cat.orderId} order={cat}></OrderCard>
          ))}
        </SimpleGrid>
      </Stack>
    </>
  );
}
