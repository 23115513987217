import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useShowSuccessToast } from "hooks/ToastUtil";
import { boardSalesOfficer } from "http/sales";
import { useState } from "react";
import SalesOfficerForm from './SalesOfficerForm';

export default function AddSalesOfficers({ onClose, isOpen, onSuccess }) {
  const [ErrorMessage, setErrorMessage] = useState('');
  const toast = useShowSuccessToast()

  const submitHandler = async (data) => {
    const response = await boardSalesOfficer(data);
    if (response.error) {
      setErrorMessage(response.error);
    }
    onClose();
    onSuccess();
    toast('Board Sales Officer', response.result);
    // forceRefetch()

  };
  return (
    <>
      <Modal
        onEsc={onClose}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={true}
        closeOnEsc={false}
        isCentered
        blockScrollOnMount={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Board Sales Officer</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            <SalesOfficerForm submitHandler={submitHandler} alertMessage={ErrorMessage} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
