import {
  Flex,
  Button,
  Stack,
  SimpleGrid,
  Heading,
  Center,
  Spinner,
  useDisclosure,
  HStack,
  Select,
  Input,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
} from "@chakra-ui/react";
import useGetCategories from "hooks/useGetCategories";
import useGetProductsRequest from "hooks/useGetProductRequest";
import useGetSubCategories from "hooks/useGetSubCategories";
import useGetProducts from "hooks/useProducts";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import AddEditProduct from "./Components/AddEditProduct";
import ProductCard from "./Components/ProductCard";
import ProductRequestCard from "./Components/ProductRequestCard";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function Products() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data: categories } = useGetCategories();
  const { data: productsRequest } = useGetProductsRequest();
  const { data: subcategories } = useGetSubCategories(
    searchParams.get("category")
  );
  const [SearchBy, setSearchBy] = useState("");
  const [FinalSearchBy, setFinalSearchBy] = useState("");
  const {
    isLoading,
    data: products,
    error,
    forceRefetch,
  } = useGetProducts(
    searchParams.get("category"),
    searchParams.get("subcategory"),
    FinalSearchBy
  );
  const [searchTimeout, setSearchTimeout] = useState(null);

  const handleSearch = (e) => {
    setSearchBy(e.target.value);
    if (searchTimeout !== null) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        clearTimeout(searchTimeout);
        setSearchTimeout(null);
        if (e.target.value.length === 0 || e.target.value.length > 2) {
          setFinalSearchBy(e.target.value);
        }
      }, 1000)
    );
  };

  return (
    <>
      <Tabs isFitted variant="enclosed" minH={"600px"}>
        <TabList height={12} >
          <Tab fontWeight={'extrabold'} fontSize={18} _selected={{ bg: "gray.400"}}>Live Products</Tab>&nbsp;
          <Tab fontWeight={'extrabold'} fontSize={18} _selected={{ bg: "gray.400"}}>Products Request</Tab>&nbsp;
        </TabList>
        <TabPanels>
          <TabPanel overflowY="auto" height="100%">
            <Flex >
              <HStack spacing={10} w={"100%"} >
                <HStack>
                  <Heading size={"xs"}>Category</Heading>
                  <Select
                    border={'0px'}
                    placeholder="Select Category"
                    onChange={(e) => {
                      setSearchParams({
                        category: e.target.value,
                      });
                    }}
                  >
                    {categories?.result?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                </HStack>
                <HStack>
                  <Heading size={"xs"} minW="100px">
                    Sub-Category
                  </Heading>
                  <Select
                    border={'0px'}
                    placeholder="Select Sub Category"
                    onChange={(e) => {
                      setSearchParams({
                        category: searchParams.get("category"),
                        subcategory: e.target.value,
                      });
                    }}
                  >
                    {subcategories?.result?.subCategories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                </HStack>
                <HStack flexGrow={1}>
                  <Heading size={"xs"} minW="100px">
                    Search :
                  </Heading>
                  <Input
                    border={'0px'}
                    height={12}
                    // bg={"white"}
                    type="text"
                    placeholder="Search here"
                    value={SearchBy}
                    onChange={handleSearch}
                  />
                </HStack>
                <Flex direction={"row-reverse"}>
                  <Button
                    colorScheme={"brand"}
                    leftIcon={<AiOutlinePlus />}
                    onClick={onOpen}
                  >
                    Add New Product
                  </Button>
                </Flex>
              </HStack>
            </Flex>

            <Stack spacing={2} mt={6}>
              {isLoading ? (
                <Center
                  w={"full"}
                  boxShadow={"3xl"}
                  rounded="md"
                  p={0}
                  color={"gray.500"}
                  bg={"gray.100"}
                >
                  <PropagateLoader
                    color={'purple'}
                    loading={isLoading}
                    aria-label="Loading"
                    data-testid="loader"
                  />
                  {/* <Spinner color="purple"/> */}

                </Center>
              ) : (
                <></>
              )}
              {!isLoading &&
                !error &&
                (products === null || products.length === 0) ? (
                <Center
                  w={"full"}
                  boxShadow={"xl"}
                  rounded="md"
                  p={5}
                  color={"gray.500"}
                  bg={"gray.100"}
                >
                  No products
                </Center>
              ) : (
                <></>
              )}
              <SimpleGrid
                columns={[1, 1, 2, 3, 5]}
                pl={["30px", 0, "10px", "18px", "40px", "50px"]}
              >
                {products?.map((prd) => (
                  <ProductCard data={prd} key={prd.productCode} rest={{ my: 5 }} />
                ))}
              </SimpleGrid>
            </Stack>
            <AddEditProduct
              isOpen={isOpen}
              onClose={onClose}
              product={null}
              onSuccess={() => {
                forceRefetch({});
              }}
              isEdit={false}
            ></AddEditProduct>
          </TabPanel>
          <TabPanel overflowY="auto" height="100%">
            {isLoading ? (
              <Center
                w={"full"}
                boxShadow={"3xl"}
                rounded="md"
                p={0}
                color={"gray.500"}
                bg={"gray.100"}
              >
                <Spinner />
              </Center>
            ) : (
              <></>
            )}
            <SimpleGrid
              columns={[1, 1, 2, 3, 5]}
              pl={["30px", 0, "10px", "18px", "40px", "50px"]}
            >
              {(productsRequest === null || productsRequest.length === 0) ? (
                <></>
              ) : (
                productsRequest?.map((prd) => (
                  <ProductRequestCard data={prd} key={prd.variantId} rest={{ my: 5 }} />
                ))
              )}
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
